import React from 'react';
// import { ICartResources } from '../../definition-extensions/cart.ext.props.autogenerated';
// import { ICheckoutResources } from '../../definition-extensions/checkout.ext.props.autogenerated';


interface ILinePaginationProps {
    nextPage: (e: React.MouseEvent<HTMLButtonElement>) => void,
    prevPage: (e: React.MouseEvent<HTMLButtonElement>) => void,
    changePage: (page: number) => void,
    currentPage: number,
    numPages: number,
    prev: string,
    next: string
}

/**
 * Renders pagination navigation on the page, which displays 4 buttons that allow
 * user to navigate to the first, last, next or previous pages. Also displays 
 * the current page out of the total number of pages. ( ie. 5/7 )
 *
 * @param {(e: React.MouseEvent<HTMLButtonElement>) => void} nextPage
 * @param {(e: React.MouseEvent<HTMLButtonElement>) => void} prevPage
 * @param {(page: number) => void} changePage
 * @param {number} currentPage
 * @param {number} numPages
 * @param {ICartResources} resources
 * @return {*}  {React.ReactNode}
 */
export const LinePagination : React.FC<ILinePaginationProps> = ({
    nextPage,
    prevPage,
    changePage,
    currentPage,
    numPages,
    prev,
    next}) => {
    const firstPage: number = 1;
    
    return (
  
    <div className="msc-cart__pagination">
      {numPages > 0 &&
        <>
          {/* {currentPage !== 0 && */}
            <>
              <button disabled={currentPage + 1 === firstPage} className={`msc-cart__pagination__btn btn-first msc-btn ${currentPage + 1 === firstPage ? 'btn-disabled' : ''}`} onClick={() => changePage(0)}>
              {`${firstPage}`}
              </button>
              <button disabled={currentPage + 1 === firstPage} className={`msc-cart__pagination__btn btn-prev msc-btn ${currentPage + 1 === firstPage ? 'btn-disabled' : ''}`} onClick={prevPage}>
                {prev}
              </button>
            </>
        {/* } */}
          <div className="msc-cart__pagination__count">
            <span className="msc-cart__pagination__count-text">
                {currentPage + 1} / {numPages}
            </span>
          </div>
          {/* {currentPage !== numPages && */}
            <>
              <button disabled={currentPage + 1 === numPages} className={`msc-cart__pagination__btn btn-next msc-btn ${currentPage + 1 === numPages ? 'btn-disabled' : ''}`} onClick={nextPage}>
                {next}
              </button>
              <button disabled={currentPage + 1 === numPages} className={`msc-cart__pagination__btn btn-last msc-btn ${currentPage + 1 === numPages ? 'btn-disabled' : ''}`} onClick={() => changePage(numPages-1)}>
                {`${numPages}`}
              </button>
            </>
          {/* } */}
        </>
      }
    </div>
  )};
  
  